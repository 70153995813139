
import Vue, { PropType } from 'vue';
import NewFavoriteFolder from '@/components/lh-ui/NewFavoriteFolder/NewFavoriteFolder.vue';
import ScrollContainer from '@/components/lh-ui/ScrollContainer/ScrollContainer.vue';
import ButtonIcon from '@/components/lh-ui/ButtonIcon/ButtonIcon.vue';
import { isMobile } from '@/utils';

export default Vue.extend({
  name: 'FavoriteMenu',
  components: {
    ButtonIcon,
    NewFavoriteFolder,
    ScrollContainer,
  },
  props: {
    favorite: {
      required: false,
      type: Object as PropType<IFavoriteItem | null>,
      default: null,
    },
    favoriteFolder: {
      type: Object as PropType<IFolderItem | null>,
      default: null,
    },
    folders: {
      required: true,
      // type: Object as PropType<IFolders>,
      type: Array as PropType<Array<IFolderItem>>,
    },
    addMode: {
      required: true,
      type: String as PropType<'button' | 'input'>,
    },
    active: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isActions: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  computed: {
    foldersListClass (): any {
      return `lh-folders-list--${this.addMode}`;
    },
    isFavorite (): boolean {
      return Boolean(this.favorite);
    },
    isShowDeleteButton (): boolean {
      const { isFavorite, addMode } = this;

      if (!isFavorite) {
        return false;
      }

      return isMobile()
        ? addMode === 'button'
        : true;
    },
  },
  methods: {
    onUpdateMode (mode: string): void {
      this.$emit('update:addMode', mode);
    },
    getSuffixName (name: string): string {
      const escapeName = name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      const regex = new RegExp(`^${escapeName}(\\s*)?(\\((\\d*)\\))?$`);

      // const duplicates = this.folders.items.filter((folder) => regex.test(folder.name));
      const duplicates = this.folders.filter(folder => regex.test(folder.name));

      const indexes = duplicates.map((folder) => {
        const result = folder.name.match(regex);
        if (result) {
          return Number.isNaN(parseInt(result[3], 10))
            ? 0
            : parseInt(result[3], 10);
        }
        return 0;
      });

      return indexes.length
        ? ` (${Math.max(...indexes) + 1})`
        : '';
    },
    onDeleteFavorite (): void {
      this.$emit('favorite-delete', this.favorite);
    },
    onCreateFolder (nameFolder: string): void {
      const newNameFolder = `${nameFolder}${this.getSuffixName(nameFolder)}`;
      if (!this.favorite) {
        // если статья НЕ в избранном, то создаем папку
        // и добавляем туда статью
        this.$emit('favorite-create-folder-and-add', newNameFolder);
      } else {
        // если статья уже в избранном, то создаем папку
        // и переносим туда статью
        this.$emit('favorite-create-folder-and-transfer', newNameFolder, this.favorite);
      }
      this.$emit('update:addMode', 'button');
    },
    isFavoriteFolder (folder: IFolderItem): boolean {
      return this.favorite
        ? folder.id === this.favorite.folder_id
        : folder.id === this.favoriteFolder?.id;
    },
    onClickFolder (folder: IFolderItem): void {
      if (!this.favorite && !this.folders) {
        return;
      }

      // если статья в избранном и кликаем на нее
      if (this.favorite && this.favorite.folder_id === folder.id) {
        return;
      }

      // если статья в избранном и кликаем по другой папке
      // - переносим избранную статью в другую папку
      if (this.favorite) {
        this.$emit('favorite-transfer-to-folder', this.favorite, folder);

        // в других случаях - добавляем статья в избранное в эту папку
      } else {
        this.$emit('favorite-add-to-folder', folder);
      }
    },
  },
});
