
import Vue, { PropType } from 'vue';

import { Img } from '@devhacker/shared/utils/image/types';
import ArticleCardCategoryIcon from '@/components/lh-ui/ArticleCardCategoryIcon/ArticleCardCategoryIcon.vue';
import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';
import ArticleCardControls from '@/components/lh-ui/ArticleCardControls/ArticleCardControls.vue';
import AdminControlsPanel from '@/components/lh-ui/AdminControlsPanel/AdminControlsPanel.vue';
import ArticleCardMeta from '@/components/lh-ui/ArticleCardMeta/ArticleCardMeta.vue';
import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';
import articleCardMixin from '@/mixins/ArticleCard';

type Covers = {
  mobile: string;
  mobileRetina: string;
  bigMobile: string;
  bigMobileRetina: string;
  desktop: string;
  desktopRetina: string;
};

export default Vue.extend({
  name: 'ArticleCardMiddle',
  components: {
    TheLinkUi,
    ArticleCardControls,
    AdminControlsPanel,
    ArticleCardCategoryIcon,
    ArticleCardMeta,
    AppPicture,
  },
  mixins: [articleCardMixin],
  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
    internalUrl: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    imgAlt: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    imgLoading: {
      type: String as PropType<'eager' | 'auto' | 'lazy'>,
      default: 'lazy',
    },
    images: {
      type: Array as PropType<Img[]>,
      required: false,
      default: null,
    },
    defaultImages: {
      type: Array as PropType<Img[]>,
      required: false,
      default: null,
    },
    dateText: {
      type: String as PropType<string>,
      required: true,
    },
    categories: {
      type: Array as PropType<Array<IArticleCategory>>,
      required: false,
      default: () => [],
    },
    commentsEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    commentsCount: {
      type: Number as PropType<number>,
      required: false,
      default: 0,
    },
    authorText: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    authorUrl: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    isAdvertising: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isPromo: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isTeaser: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isAuthenticatedInWordpress: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    editLinkHref: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    viewsCount: {
      type: Number as PropType<number>,
      required: false,
      default: null,
    },
    user: {
      type: Object as PropType<IUser>,
      default: null,
    },
    favorite: {
      required: false,
      type: Object as PropType<IFavoriteItem | null>,
      default: null,
    },
    folders: {
      required: true,
      type: Object as PropType<IFolders>,
    },
    isShowFavoriteFolderName: {
      type: Boolean,
      default: false,
    },
    favoriteMenuOffsets: {
      type: Object as PropType<TOffsets | null>,
      default: null,
    },
    loadingViews: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    advertLabel: {
      default: '',
      type: String as PropType<'empty' | 'partner' | 'abs' | 'press-release' | 'social-ads'>,
    },
    partnerText: {
      type: String as PropType<string>,
      default: '',
    },

    // Consume from mixin so it does not appear in the html
    type: {
      type: String as PropType<string>,
      default: '',
    },
    adsToken: {
      default: '',
      type: String as PropType<string>,
    },
    tags: {
      type: Array as PropType<Array<IArticleTags>>,
      required: false,
      default: () => [],
    },
  },
  data () {
    return {
      covers: null as null | Covers,
    };
  },
  computed: {
    categoryIcon (): string {
      const { categories } = this;
      const categoryWithIcon = categories.find(item => Boolean(item?.icon));
      return categoryWithIcon?.icon ?? '';
    },
    categoryLink (): string | null {
      const { categories } = this;
      const categoryWithIcon = categories.find(item => Boolean(item?.url));
      return categoryWithIcon?.url ?? null;
    },
  },

  methods: {
    onMouseOver (): void {
      const articleCard = this.$refs.articleCard as HTMLElement;
      if (articleCard) {
        articleCard.classList.remove('lh-article-card--no-hover');
      }
    },
  },
});
