
import Vue, { PropType, VNode } from 'vue';

export default Vue.extend({
  name: 'TheLinkUi',

  props: {
    to: {
      type: String as PropType<string>,
      default: null,
    },
    href: {
      type: String as PropType<string>,
      default: null,
    },
    name: {
      type: String as PropType<string>,
      default: null,
    },
    target: {
      type: String as PropType<string>,
      default: null,
    },
  },
  render (h): VNode {
    const {
      href,
      name,
      to,
      $slots,
      target,
    } = this;

    return h(
      'a',
      {
        attrs: {
          ...(href ? { href: href ?? to } : {}),
          ...(name ? { title: name } : {}),
          ...(name ? { 'aria-label': name } : {}),
          ...(target ? { target } : {}),
        },
      },
      $slots.default,
    );
  },
});
