
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ButtonIcon',
  props: {
    type: {
      type: String,
      default: '',
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String as PropType<string>,
      required: false,
      default: '#969698',
    },
    hoverColor: {
      type: String as PropType<string>,
      required: false,
      default: 'black',
    },
    // цвет текста у кнопки
    colorText: {
      type: String as PropType<string>,
      required: false,
      default: '#5C5C5C',
    },
    // цвет текста у кнопки при наведении
    colorTextHover: {
      type: String as PropType<string>,
      required: false,
      default: 'black',
    },
  },
  computed: {
    buttonClass (): any {
      return this.type ? `lh-button-icon--${this.type}` : null;
    },
    buttonTextClass (): any {
      return {
        'lh-button-icon__text--hidden': this.onlyIcon,
      };
    },
    buttonStyles (): any {
      return {
        '--base-color': this.color,
        '--hover-color': this.hoverColor,
        '--base-color-text': this.colorText,
        '--hover-color-text': this.colorTextHover,
      };
    },
  },
});
