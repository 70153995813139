
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'FavoriteButton',

  props: {
    checked: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  computed: {
    computedClass (): { [propName: string]: boolean } {
      return {
        'lh-button__favorite_checked': this.checked,
      };
    },
  },

  methods: {
    onClick () {
      this.$emit('click');
    },
  },
});
