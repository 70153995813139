
import { getImagesBySizes } from '@devhacker/shared/utils/image';
import Vue, { PropType } from 'vue';
import { ARTICLE_IMAGE_SIZES } from '~/constants/imageSizes';
import { getRetinaSrcSet } from '~/utils/retinaSrcSet';

export default Vue.extend({
  name: 'PollArticleCard',

  action: 'Читать статью',

  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    cover: {
      type: Object as PropType<Cover>,
      default: null,
    },
    defaultCover: {
      type: Object as PropType<DefaultCover>,
      default: null,
    },
    imagesHost: {
      type: String as PropType<string>,
      required: true,
    },
    location: {
      type: String as PropType<string>,
      default: '',
    },
  },

  computed: {
    pollArticleCardStyles (): any {
      return this.cover
        ? { 'background-image': `url(${this.coverSrcs.normal});` }
        : null;
    },
    pollArticleCardClass (): any {
      return {
        [`lh-poll-article-card--${this.location}`]: this.location ?? null,
      };
    },
    coverSrcs (): {normal: string, retina: string} {
      const images = Object.keys(this.cover?.sizes || {}).map(size => this.cover.sizes[size]);
      const cover = getImagesBySizes(
        [ARTICLE_IMAGE_SIZES.XXL.width],
        images,
      );

      if (cover.length) {
        return {
          normal: cover[0]?.url,
          retina: cover[1]?.url,
        };
      }

      const defaultImages = Object.keys(this.defaultCover || {}).map((size: string) => this.defaultCover[size]);
      const defaultCover = getImagesBySizes(
        [ARTICLE_IMAGE_SIZES.XXL.width],
        defaultImages,
      );

      if (defaultCover.length) {
        return {
          normal: defaultCover[0]?.url,
          retina: defaultCover[1]?.url,
        };
      }

      return {
        normal: '',
        retina: '',
      };
    },

    coverSrcSet (): string | void {
      return getRetinaSrcSet(this.coverSrcs.normal, this.coverSrcs.retina, this.imagesHost);
    },
  },

  methods: {
    handleLinkClick () {
      this.$emit('link-click', this.url);
    },
  },
});
