
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'AdminControlsPanel',
  props: {
    editLinkHref: {
      type: String as PropType<string>,
      required: true,
    },
    viewsCount: {
      type: Number as PropType<number>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  computed: {
    computedViewsClass (): 'lh-admin-controls-panel__views_loading' | '' {
      return this.loading ? 'lh-admin-controls-panel__views_loading' : '';
    },
  },
  methods: {
    onClickRestoreButton () {
      const isNotLoading = !this.loading;

      if (isNotLoading) {
        /**
         * Генерируется при клике на кнопку "обновить просмотры"
         */
        this.$emit('click-on-restore-button');
      }
    },
  },
});
