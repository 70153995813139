
import Vue, { PropType } from 'vue';

import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';

const CATEGORIES_COUNT = 2;

export default Vue.extend({
  name: 'ArticleCardMeta',
  components: {
    TheLinkUi,
  },

  inject: {
    isDateShowArticleCardSmall: {
      default () {
        return () => true;
      },
    },
  },

  props: {
    disableBreadcrumbCircularLink: {
      type: Boolean,
      default: false,
    },
    dateText: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    isPromo: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isAdvertising: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    categories: {
      type: Array as PropType<Array<IArticleCategory>>,
      required: false,
      default: () => [],
    },
    categoriesCount: {
      type: Number as PropType<number>,
      required: false,
      default: CATEGORIES_COUNT,
    },
    color: {
      type: String as PropType<string>,
      required: false,
      default: '#5C5C5C',
    },
    hoverColor: {
      type: String as PropType<string>,
      required: false,
      default: 'black',
    },
    tags: {
      type: Array as PropType<Array<IArticleTags>>,
      required: false,
      default: () => [],
    },
    type: {
      type: String as PropType<string>,
      default: '',
    },
    partnerText: {
      type: String as PropType<string>,
      default: '',
    },
  },
  data () {
    return {
      container: {} as HTMLElement,
    };
  },
  computed: {
    isAds (): boolean {
      return this.isAdvertising || this.isPromo;
    },
    dateVisible (): boolean {
      return Boolean(this.dateText) && !this.isAds;
    },
    computedCategories (): IArticleCategory[] {
      // Отображать категории в том порядке, в котором приходит с бэка
      const breadCrumbs = this.categories.length ? this.categories : this.tags;
      return breadCrumbs.slice(0, this.categoriesCount);
    },
  },
  mounted () {
    this.container = this.$refs.container as HTMLElement;
  },
  methods: {
    isCircularLink (categoryItem: IArticleCategory): boolean {
      // @ts-ignore
      const path = this.$route?.path || '';

      return path.includes(categoryItem.url);
    },
  },
});
